import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #f2f2f2;
`;

const TableHeading = styled.th`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    // background-color: #f2f2f2;
  }
`;

function DescriptionTable({ data }) {
    return (
    <TableWrapper>
        <TableHead>
        <tr>
            <TableHeading>Transaction Date</TableHeading>
            {/* <TableHeading>Balance</TableHeading> */}
            <TableHeading>Credit</TableHeading>
            <TableHeading>Debit</TableHeading>
            <TableHeading>Description</TableHeading>
        </tr>
        </TableHead>
        <tbody>
        {data.map(row => (
            <TableRow key={row['TRANSACTION DATE']}>
            <TableData>{row['TRANSACTION DATE']}</TableData>
            {/* <TableData>{row['BALANCE']}</TableData> */}
            <TableData>{row['CREDIT']}</TableData>
            <TableData>{row['DEBIT']}</TableData>
            <TableData>{row['DESCRIPTION']}</TableData>
            </TableRow>
        ))}
        </tbody>
    </TableWrapper>
    );
}


export default DescriptionTable;


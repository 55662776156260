import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';


import ReceiptIcon from '@material-ui/icons/Receipt';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
// import PayLender from 'assets/img/PayLender.jpg';
// import PayLender from '../assets/img/PayLender.jpg';
import { linearIcons } from '../styles/utils';


const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  // margin-right: 50px;
//   background-color: #f2f7f4;
  padding: 24px;
  border-right: 1px solid #ccc;
`;

// const linearIcons = '#003468';
// // const linearIcons = 'linear-gradient(157deg, rgba(21,12,58,1) 0%, rgba(37,150,190,1) 48%, rgba(0,0,0,1) 100%, rgba(76,97,255,1) 100%)';

const Button = styled.button`
  width: 100%;
  padding: 6%;
  margin-bottom: 16px;
  background-color: ${linearIcons};
  // background-image: ${linearIcons};
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 100%;
  cursor: pointer;

`;

const SettingsButton = styled.button`
  position: fixed;
  bottom: 50px;
  right: 24px;
  background-color: #2596be;
  border: none;
  border-radius: 50%;
  font-size: 150%;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

export const TextStyle = styled.div`
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
  font-size: 2rem;
  color: #FC4D02;
  margin-top: 15px;
  font-family: 'Saira', sans-serif;
`;


const Sidebar = () => {
  // setIsLoading(true);
  const userData = JSON.parse(localStorage.getItem('userData'));
  // console.log("userData.icon", userData.icon)

  let buttonsToRender = [
          <div
          style={{
            marginTop: '4rem',
          }}
          >
        <Link to="/profile">
          <Button>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
              <AccountCircleIcon />
              <span style={{ marginLeft: '8px' }}>Profile</span>
            </div>
          </Button>
        </Link>
        <Link to="/payslip">
        <Button>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            <ReceiptIcon />
            <span style={{ marginLeft: '8px' }}>Payslip</span>
          </div>
        </Button>
      </Link>
        <Link to="/business-loans">
        <Button>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            <BusinessCenterIcon />
            <span style={{ marginLeft: '8px' }}>Business loans</span>
          </div>
        </Button>
      </Link>
      </div>
  ];

  return (
    <SidebarContainer >
      <div style={{height: '6rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
        <div style={{height: '100%', width: '100%', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {userData.icon ? (
        // If userData.icon is available, display the image
        <img
          src={userData.icon.replace(/^"(.*)"$/, '$1')}
          style={{
            width: '80px',
            height: '80px',
            textAlign: 'center',
            marginTop: '20px',
            borderRadius: '50%', // Make the image circular
          }}
          alt="User Icon"
        />
      ) : (
        <div
          style={{
            width: '100px',
            height: '100px',
            textAlign: 'center',
            marginTop: '20px',
            background: `${linearIcons}`, // Background color for the div
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '80px',
              height: '80px',
              textAlign: 'center',
              // marginTop: '20px',
              borderRadius: '50%', // Make the div circular
              background: 'lightgray', // Background color for the div
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px', // Adjust the font size as needed
            }}
          >
            {userData.name ? userData.name[0].toUpperCase() : ''}
          </div>
        </div>
      )}
        </div>
      </div>
      {buttonsToRender}
      <SettingsButton>⚙️</SettingsButton>
    </SidebarContainer>
  );
};

export default Sidebar;
import React from 'react'
import Title from './Title';
import { Link } from 'react-router-dom';
// import textureone from 'assets/img/texttwo.avif'
// import textthree from 'assets/img/textthree.jpg'
// import textfour from 'assets/img/textfour.avif'




// import ControlPointIcon from '@mui/icons-material/ControlPoint';
// import Image1 from 'assets/icons/love-favorite-heart-svgrepo-com.svg';
// import Image7 from 'assets/icons/profits-graph-svgrepo-com.svg'
// import ReceiptIcon from '@mui/icons-material/Receipt';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
//

import styled from 'styled-components';
// import MiniCards from './MiniCards';

const Button = styled.button`
  width: 100%;
  height: 80%;
  padding: 6%;
  margin-bottom: 16px;
//   background-image: linear-gradient(157deg, rgba(150,141,188,1) 0%, rgba(255,255,255,1) 48%, rgba(76,97,255,1) 100%, rgba(0,0,0,1) 100%);
  background: black;
  background: ${props => props.colorbackground};
  color: ${props => props.fontColor};
  border: none;
  border-radius: 8px;
  font-size: 100%;
  cursor: pointer;
`;


const SecComponent = ({defColor, userData}) => {
    const colorbackground = 'black';
  return (
    <div
      style={{
        // backgroundColor: 'red',
        borderRadius: '20px',
        background: defColor,
        display: 'grid', gridTemplateRows: '20% 80%',
        padding: '20px',
        // padding: '12px',
      }}
    >
        <Title userData={userData}/>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '20px'}}>
            {/* <MiniCards Color={defColor} image={textureone}/> */}

            {/* <div style={{background: 'linear-gradient(to right, #16bffd, #cb3066)', borderRadius: '12px', */}
            <div style={{background: colorbackground, borderRadius: '12px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{background: defColor, borderRadius: '10px', height: '98%', width: '98%',
                            display: 'grid', gridTemplateRows: '50% 50%',
                            }}>
                    <div style={{
                        background: colorbackground,
                        // background: 'linear-gradient(157deg, rgba(150,141,188,1) 0%, rgba(255,255,255,1) 48%, rgba(76,97,255,1) 100%, rgba(0,0,0,1) 100%)',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '20px',
                        borderRadius: '12px',
                        position: 'relative', // ensure parent div is positioned relatively
                    }}>
                    </div>

                    <div style={{marginRight: '20px', marginLeft: '20px', marginBottom: '10px', marginTop: '20px',
                                display: 'grid', gridTemplateRows: '30% 70%'}}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', position: 'relative'}}>
                            <div style={{position: 'absolute', left: '0', color: "white",
                                         fontSize: '100%', fontFamily: 'Century Gothic'}}>
                                Payslip
                            </div>
                            <WysiwygIcon style={{color: "white", position: 'absolute', right: '0', }}/>
                        </div>
                        <Link to="/payslip">
                        <Button colorbackground="black" fontColor="white">
                            Proceed to Payslip
                        </Button>
                        </Link>

                    </div>

                </div>
            </div>





            {/* <div style={{background: 'linear-gradient(157deg, rgba(150,141,188,1) 0%, rgba(255,255,255,1) 48%, rgba(76,97,255,1) 100%, rgba(0,0,0,1) 100%)', borderRadius: '12px', */}
            <div style={{background: colorbackground, borderRadius: '12px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{background: defColor, borderRadius: '10px', height: '98%', width: '98%',
                            display: 'grid', gridTemplateRows: '50% 50%',
                            }}>
                    <div style={{
                        background: colorbackground,
                        // background: 'linear-gradient(157deg, rgba(150,141,188,1) 0%, rgba(255,255,255,1) 48%, rgba(76,97,255,1) 100%, rgba(0,0,0,1) 100%)',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '20px',
                        borderRadius: '12px',
                        position: 'relative', // ensure parent div is positioned relatively
                    }}>
                    </div>

                    <div style={{marginRight: '20px', marginLeft: '20px', marginBottom: '10px', marginTop: '20px',
                                display: 'grid', gridTemplateRows: '30% 70%'}}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', position: 'relative'}}>
                            <div style={{position: 'absolute', left: '0', color: "white",
                                         fontSize: '100%', fontFamily: 'Century Gothic'}}>
                                Business loans
                            </div>
                            <WysiwygIcon style={{color: "white", position: 'absolute', right: '0', }}/>
                        </div>
                        <Link to="/business-loans">
                        <Button colorbackground="black" fontColor="white">
                            Proceed to Business loans
                        </Button>
                        </Link>

                    </div>
                </div>
            </div>



            <div style={{background: colorbackground, borderRadius: '12px',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        pointerEvents: 'none', opacity: 0.5 // if the user does not qualify
                        }}>
                <div style={{background: defColor, borderRadius: '10px', height: '98%', width: '98%',
                            display: 'grid', gridTemplateRows: '50% 50%',
                            }}>
                    <div style={{
                        background: colorbackground,
                        // background: 'linear-gradient(157deg, rgba(150,141,188,1) 0%, rgba(255,255,255,1) 48%, rgba(76,97,255,1) 100%, rgba(0,0,0,1) 100%)',
                        marginTop: '20px',
                        marginRight: '20px',
                        marginLeft: '20px',
                        borderRadius: '12px',
                        position: 'relative', // ensure parent div is positioned relatively
                    }}>
                        {/* <img src={textfour} alt="icon" style={{
                            position: 'absolute', // position image absolutely within the parent div
                            top: 0, // position at the top of parent div
                            left: 0, // position at the left of parent div
                            width: '100%', // make the image width fill the parent div
                            height: '100%', // make the image height fill the parent div
                            objectFit: 'cover', // scale and crop the image to fill the parent div
                            borderRadius: '12px', // add rounded corners to match the parent div
                        }} /> */}
                    </div>

                    <div style={{marginRight: '20px', marginLeft: '20px', marginBottom: '10px', marginTop: '20px',
                                display: 'grid', gridTemplateRows: '30% 70%'}}>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', position: 'relative'}}>
                            <div style={{position: 'absolute', left: '0', color: 'white',
                                         fontSize: '100%', fontFamily: 'Century Gothic'}}>
                                Other services
                            </div>
                            <WysiwygIcon style={{color: 'white', position: 'absolute', right: '0', }}/>
                        </div>


                        <Button>
                            Proceed to other services
                        </Button>

                    </div>

                </div>
            </div>


        </div>


    </div>
  )
}

export default SecComponent

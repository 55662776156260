import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #f2f2f2;
`;

const TableHeading = styled.th`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     // background-color: #f2f2f2;
//   }
// `;

function SummaryTableNew({res}) {
  let yr1Bal, formattedDate, yr1CreDeb, startFormattedDate;
  if ('Year1' in res.data.balance) {
      yr1Bal = res.data.balance.Year1.filter(item => item.Date === 'Total')[0];
      formattedDate = res.data.balance.Year1[res.data.balance.Year1.length - 2]['Date'];
      startFormattedDate = res.data.balance.Year1[0]['Date'];
      // formattedDate = dates;
      yr1CreDeb = res.data.creditDebit.Year1.filter(item => item.Date === "Total")[0];
  }

  let yr2Bal, yr2CreDeb, formattedDate2, startFormattedDate2;
  if ('Year2' in res.data.balance) {
      yr2Bal = res.data.balance.Year2.filter(item => item.Date === "Total")[0];
      yr2CreDeb = res.data.creditDebit.Year2.filter(item => item.Date === "Total")[0];
      formattedDate2 = res.data.balance.Year2[res.data.balance.Year2.length - 2]['Date'];
      startFormattedDate2 = res.data.balance.Year2[0]['Date'];
      // date2 = new Date(dates2);
      // formattedDate2 = date2.getFullYear();
  }

  return (
      <TableWrapper>
      <TableHead>
        <tr>
          <TableHeading></TableHeading>
          {'Year1' in res.data.balance && <TableHeading>Year One</TableHeading>}
          {'Year2' in res.data.balance && <TableHeading>Year Two</TableHeading>}
          {/* {['Year One', 'Year Two'].map((year, index) => (
            <TableHeading key={index}>{year}</TableHeading>
          ))} */}
        </tr>
      </TableHead>
      <tbody>
        <tr>
          <TableData>Start Date</TableData>
          {'Year1' in res.data.balance && <TableData>{startFormattedDate}</TableData>}
          {'Year2' in res.data.balance && <TableData>{startFormattedDate2}</TableData>}
          {/* <TableData>{startFormattedDate}</TableData>
          <TableData>{startFormattedDate2}</TableData> */}
        </tr>
        <tr>
          <TableData>End Date</TableData>
          {'Year1' in res.data.balance && <TableData>{formattedDate}</TableData>}
          {'Year2' in res.data.balance && <TableData>{formattedDate2}</TableData>}
          {/* <TableData>{formattedDate}</TableData>
          <TableData>{formattedDate2}</TableData> */}
        </tr>
        <tr>
          <TableData>Mean Balance</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1Bal["Balance (Mean)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2Bal["Balance (Mean)"]}</TableData>}
          {/* <TableData>{yr1Bal["Balance (Mean)"]}</TableData>
          <TableData>{yr2Bal["Balance (Mean)"]}</TableData> */}
        </tr>
        <tr>
          <TableData>Debit Count</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Debit (Count)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Debit (Count)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Debit (Count)"]}</TableData>
          <TableData>{yr2CreDeb["Debit (Count)"]}</TableData> */}
        </tr>
        <tr>
          <TableData>Credit Count</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Credit (Count)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Credit (Count)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Credit (Count)"]}</TableData>
          <TableData>{yr2CreDeb["Credit (Count)"]}</TableData> */}
        </tr>
        <tr>
          <TableData>Mean Debit Value</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Debit (Mean)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Debit (Mean)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Debit (Mean)"]}</TableData>
          <TableData>{yr2CreDeb["Debit (Mean)"]}</TableData> */}
        </tr>
        <tr>
          <TableData>Mean Credit Value</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Credit (Mean)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Credit (Mean)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Credit (Mean)"]}</TableData>
          <TableData>{yr2CreDeb["Credit (Mean)"]}</TableData> */}
        </tr>
        <tr>
          <TableData>Total Credit</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Credit (Sum)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Credit (Sum)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Credit (Sum)"]}</TableData>
          <TableData>{yr2CreDeb["Credit (Sum)"]}</TableData> */}
      </tr>
        <tr>
          <TableData>Total Debit</TableData>
          {'Year1' in res.data.balance && <TableData>{yr1CreDeb["Debit (Sum)"]}</TableData>}
          {'Year2' in res.data.balance && <TableData>{yr2CreDeb["Debit (Sum)"]}</TableData>}
          {/* <TableData>{yr1CreDeb["Debit (Sum)"]}</TableData>
          <TableData>{yr2CreDeb["Debit (Sum)"]}</TableData> */}
      </tr>
  </tbody>
</TableWrapper>
);
}


export default SummaryTableNew;

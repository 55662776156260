import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const Navbar = ({defColor, userData}) => {
  return (
    <div style={{
      height: '100px',
      width: '100%',
      borderRadius: '10px',
      background: defColor,
      display: 'grid',
      gridTemplateColumns: '40% 60%',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
        }}>
        <div
            style={{
                    // background: 'green',
                    display: 'grid',
                    gridTemplateColumns: '115px 400px'
                }}
        >
        <div style={{float: 'left', width: '45px', margin: '15px 10px 20px 20px',background: 'black', padding: '18px', borderRadius: '12px'}}>
            <div style={{background: 'white', padding: '4px', borderRadius: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <AccountCircleIcon style={{ color: '#2596be' }}/>
            {/* <AccountCircleIcon style={{ color: '#2596be' }}/> */}
            </div>
        </div>

        <div style={{float: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontSize: '1em', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
            {userData.name}
            </div>
            <div style={{ fontSize: '1em', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
            {userData.email}
            </div>
        </div>
        </div>
    </div>
  )
}

export default Navbar
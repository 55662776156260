import React from 'react'

const ProfileCard = ({userData, defColor}) => {
  return (
    <div style={{
        backgroundColor: 'red',
        borderRadius: '20px',
        background: defColor,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',

      }}>
        <div style={{float: 'top', padding: '10px', marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

          <div style={{ fontSize: '1.2rem', marginLeft: '6px', fontWeight: '600', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
            Profile Information
          </div>


          <div style={{ fontSize: '.8rem', marginTop: '30px' ,marginLeft: '5px', padding: '0.1em 0', margin: '5px',  color: 'white', fontFamily: 'Century Gothic' }}>
            If you can't decide, the answer is no. If there are two paths that seem equally difficult, choose the one that is more painful in the short term. (Avoiding pain creates the illusion of equality between the paths.)
          </div>

          <div style={{marginLeft: '5px', marginTop: '60px', float: 'left', }}>
              <div style={{ fontSize: '.8rem', padding: '0.3em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
                Full name: {userData.name}
              </div>
              <div style={{ fontSize: '.8rem', padding: '0.3em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
                Email: {userData.email}
              </div>
              {/* <div style={{ fontSize: '.8rem', padding: '0.3em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
                Company: {userData.company}
              </div>
              <div style={{ fontSize: '.8rem', padding: '0.3em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
                User category: {userData.category}
              </div>
              <div style={{ fontSize: '.8rem', padding: '0.3em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
                Mobile: {userData.mobile}
              </div> */}
          </div>

        </div>

      </div>

  )
}

export default ProfileCard
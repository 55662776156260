import React from 'react';
// import styled from 'styled-components';
// import Title from './Title';

// const Pane = styled.div`
//   border-radius: 20px;
//   height: 400px;
// `;

const FirstComponent = ({ linearIcons, userData }) => {
  return (
    <div style={{ background: linearIcons, display: 'grid', gridTemplateRows: '15% 39% 39%',
            padding: '20px', height: '500px', borderRadius: '20px', gridGap: '3%'}}>
        <div style={{ fontSize: '1.2rem', padding: '0.1em 0', margin: '0',
                      color: 'white', fontFamily: 'Century Gothic' }}>
            Profile Settings
        </div>

        

    </div>
  )
}

export default FirstComponent

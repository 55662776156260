

import React, { useState } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
// import { useHistory } from "react-router-dom";
// import { Link } from 'react-router-dom';
// import SignInImage from 'assets/img/pexels-photo-2422278.png'
// import SignInImage from 'assets/img/depositphotos_184405718-stock-photo-working-tables-computers-laptops-business.webp'
// import SignInImage from 'assets/img/pexels-photo-2528118.png'
import { Tooltip, Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
// import { userData } from './userData';
// import SignUp from "components/SignUp";

const Container = styled.div`
  display: flex;
  height: 100vh;
  // width: 100vw;
  // position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: linear-gradient(to right, #4776e6, #8e54e9);
  overflow-y: hidden;
  overflow-x: hidden;
`;

const SignInContainer = styled.div`
  display: flex;
  height: 60vh;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 32px;
  // margin: 110px 0 0 150px;
  margin-left: 15%;
  margin-top: 10%;
`;

const SignInTitle = styled.h1`
  font-size: 32px;
  margin-bottom: 24px;
  color: #222;
`;

const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

const SignInInput = styled.input`
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 8px 16px;
  margin-bottom: 24px;
  width: 100%;
  font-size: 18px;

  &:focus {
    outline: none;
    border-color: #0077cc;
  }
`;

const SignInButton = styled.button`
  background-color: #2596be;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background-color: #005faa;
  }
`;

const SignInError = styled.div`
  color: red;
  margin-bottom: 24px;
  width: 100%;
`;

const Button = styled.button`
  width: 100px;
  height: 40px;
  // width: 100%;
  padding: 6%;
  // margin-bottom: 16px;
  background-color: #2596be;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 100%;
  cursor: pointer;

  &:hover {
    background-color: #005faa;
  }

`;

function SignInPage({ handleLogin  }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['csrftoken']);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Render password suffix with eye icon
  const passwordSuffix = (
    <Tooltip title={passwordVisible ? 'Hide password' : 'Show password'}>
      <span onClick={togglePasswordVisibility}>
        {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </span>
    </Tooltip>
  );

  const email_list = [
    'momojola@pagefinancials.com',
    'abadaru@prime-pay.africa',
    'oagbede@prime-pay.africa',
    'hmoyse@prime-pay.africa',
    'iokereji@prime-pay.africa',
    'eche@prime-pay.africa',
    'info@prime-pay.africa',
    'ababalola@prime-pay.africa',
    'riskhelpdesk@prime-pay.africa',
  ];


  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError("Please enter your email and password.");
      return;
    }

    // async function fetchCsrfToken() {
    //   const response = await fetch('https://api.lenda.prime-pay.africa/login_system/get-csrf-token/');
    //   const data = await response.json();
    //   return data.csrfToken;
    // }

    async function signIn(email, password) {

      if (email_list.includes(email)) {
        console.log('Sign in successful');
        const data = {
          username: email.split('@')[0],
          email: email,
        }
        localStorage.setItem('userData', JSON.stringify(data));
        navigate('/profile');
        handleLogin();
        // console.log("Now ")
      }

    //   try {
    //     const csrfToken = await fetchCsrfToken(); // retrieve the CSRF token
    //     // console.log('csrfToken', csrfToken)
    //     const response = await fetch('https://api.lenda.prime-pay.africa/login_system/admin-user/sign-in/', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'X-CSRFToken': csrfToken // include the CSRF token in the headers
    //       },
    //       body: JSON.stringify({ email, password })
    //     });
    //     if (!response.ok) {
    //       throw new Error(response.statusText);
    //     }
    //     const data = await response.json();
    //     console.log(data.message);
    //     if (email_list.includes(email)) {
    //       console.log('Sign in successful');
    //       const data = {
    //         username: email.split('@')[0],
    //         email: email,
    //       }
    //       localStorage.setItem('userData', JSON.stringify(data));
    //       navigate('/profile');
    //       handleLogin();
    //     }

    //     // if (data.message === 'Sign in successful') {
    //     //   localStorage.setItem('userData', JSON.stringify(data));
    //     //   navigate('/profile');
    //     //   handleLogin();
    //     // } else {
    //     //   setError('Incorrect email or password.');
    //     // }
    //   } catch (error) {
    //     console.error(error);
    //     setError('An error occurred while signing in. Please try again later.');
    //   }
    }
    signIn(email, password);

    // async function fetchCsrfToken() {
    //   const response = await fetch('https://167.99.81.58:443/get-csrf-token/');
    //   const data = await response.json();
    //   return data.csrfToken;
    // }

    // if (csrfToken !== undefined) {
    //   fetch('https://167.99.81.58:443/user_management/admin-user/sign-in/', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-CSRFToken': csrfToken,
    //     },
    //     body: JSON.stringify({ email, password })
    //   })
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error(response.statusText);
    //     }
    //     console.log(response);
    //     return response.json();
    //   })
    //   .then(data => {
    //     console.log(data.message);
    //     // if (data.message === 'Sign in successful') {
    //     // navigate('/profile');
    //     // window.location.href = '/profile';

    //     if (data.message === 'Sign in successful') {

    //       // localStorage.setItem('userData', data);
    //       localStorage.setItem('userData', JSON.stringify(data));
    //       // window.location.href = '/payslip';
    //       navigate('/profile');
    //       handleLogin();
    //     } else {
    //         // user is not authenticated, show error message
    //       setError('Incorrect email or password.');
    //       }
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     // setError('Incorrect email or password.');
    //     setError('An error occurred while signing in. Please try again later.');
    //   });
    // } else {
    //   console.log('csrftoken cookie not found');
    // }

  };


  return (
    <Container >
      <SignInContainer style={{marginTop: '20vh', marginLeft: '30vw', position: 'relative'}}>
        <SignInTitle>Sign In</SignInTitle>
        {error && <SignInError>{error}</SignInError>}
        <SignInForm onSubmit={handleSubmit}>
          <SignInInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
            <Input.Password
              type="password"
              placeholder="Password"
              value={password}
              suffix={passwordSuffix}
              onChange={(event) => setPassword(event.target.value)}
              style={{
                border: 'none',
                // width: '100%',
                borderBottom: '2px solid #ccc',
                padding: '8px 16px',
                marginBottom: '24px',
                width: '110%',
                fontSize: '18px'
              }}
              required
            />
            <SignInButton type="submit">Sign In</SignInButton>
            <div style={{ position: 'absolute', height: '40px', bottom: '30px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{height: '100%', marginRight: '10px', display: 'flex', alignItems: 'center'}}>Don't have an account?</div>



              <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/signup">
                  <Button>
                    <div >
                      <span>Sign Up</span>
                    </div>
                  </Button>
                </Link>
              </div>

            </div>

          </SignInForm>
        </SignInContainer>
      </Container>
    );
  };

  export default SignInPage;

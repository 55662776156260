// import React from "react";
// import { linearIcons } from '../styles/utils';

// const EnquiryTable = ({ data }) => {
//   return (
//     <div style={{ margin: "auto", marginBottom: '50px' }}>
//       <h2 style={{ textAlign: "left" }}>Enquiry History in Last 12 Months</h2>
//       <table style={{ borderCollapse: "collapse", width: "100%" }}>
//         <thead>
//           <tr>
//             <th
//               style={{
//                 background: `${linearIcons}`,
//                 color: "#FFFFFF",
//                 padding: "10px",
//               }}
//             >
//               Enquiry Date
//             </th>
//             <th
//               style={{
//                 background: `${linearIcons}`,
//                 color: "#FFFFFF",
//                 padding: "10px",
//               }}
//             >
//               Name of Enquirer
//             </th>
//             <th
//               style={{
//                 background: `${linearIcons}`,
//                 color: "#FFFFFF",
//                 padding: "10px",
//               }}
//             >
//               Enquirer Phone Number
//             </th>
//             <th
//               style={{
//                 background: `${linearIcons}`,
//                 color: "#FFFFFF",
//                 padding: "10px",
//               }}
//             >
//               Reason for Enquiry
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row) => (
//             <tr key={row.id} style={{ backgroundColor: "#FFFFFF" }}>
//               <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
//                 {row.enquiryDate}
//               </td>
//               <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
//                 {row.enquirerName}
//               </td>
//               <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
//                 {row.enquirerPhone}
//               </td>
//               <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
//                 {row.reason}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default EnquiryTable;


import React from "react";
import { linearIcons } from '../styles/utils';

const EnquiryTable = ({ searchHistory }) => {
  return (
    <div style={{ margin: "auto", marginBottom: '50px' }}>
      <h2 style={{ textAlign: "left" }}>Enquiry History</h2>
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead>
          <tr>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Enquiry Date
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Name of Enquirer
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Enquirer Phone Number
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              No. of Searches
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Reason for Enquiry
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Website URL
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Industry
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Phone Number
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              Address
            </th>
            <th
              style={{
                background: `${linearIcons}`,
                color: "#FFFFFF",
                padding: "10px",
              }}
            >
              State
            </th>
          </tr>
        </thead>
        <tbody>
          {searchHistory.map((item, index) => (
            <tr key={index} style={{ backgroundColor: "#FFFFFF" }}>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__enquiry_date}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.user__first_name}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__enquirer_phone_number}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__search_count}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__reason_for_enquiry}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__website_url}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__industry}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__enquirer_phone_number}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__address}
              </td>
              <td style={{ border: "1px solid #FFFFFF", padding: "10px", textAlign: "center" }}>
                {item.metadata__state}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EnquiryTable;

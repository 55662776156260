import React from 'react';
import styled from 'styled-components';

// const MessageContainer = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 9999;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   width: 400px;
//   height: 200px;
//   border: 1px solid black;
//   border-radius: 5px;
// `;

// const MessageContainer = styled.div`
//   position: fixed;
//   top: 10px;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 9999;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   width: 400px;
//   height: 200px;
//   border: 1px solid black;
//   border-radius: 5px;
// `;
const MessageContainer = styled.div`
  position: fixed;
  padding: 10px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: ${props => props.width || '400px'};
  height: ${props => props.height || '200px'};
  border: 1px solid black;
  border-radius: 5px;
`;



const MessageTitle = styled.h1`
  font-size: 1.5rem;
`;

const MessageText = styled.p`
  font-size: .8rem;
  margin-top: 20px;
  text-align: center;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #999;
  }
`;

const Message = ({ title, text, onClose }) => {
  return (
    <MessageContainer>
      <MessageTitle style={{color: 'black'}}>{title}</MessageTitle>
      <MessageText style={{color: 'black'}}>{text}</MessageText>
      <CloseButton onClick={onClose}>OK</CloseButton>
    </MessageContainer>
  );
};

export default Message;

// const Message = ({ title, messages, onClose }) => {
//   const messageKeys = Array.isArray(messages) ? messages : Object.keys(messages);

//   return (
//     <MessageContainer>
//       <MessageTitle style={{ color: "black" }}>{title}</MessageTitle>
//       {messageKeys.map((key) => (
//         <MessageText key={key} style={{ color: "black" }}>
//           {key}: {Array.isArray(messages) ? messages[key] : messages[key].join(", ")}
//         </MessageText>
//       ))}
//       <CloseButton onClick={onClose}>OK</CloseButton>
//     </MessageContainer>
//   );
// };

// export default Message;

//Profile.js
// import React from 'react';
// import React, { useState, useEffect } from 'react';
import React from 'react';
// import React, { useState } from 'react';
import styled from 'styled-components';
// import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router-dom';
import Navbar from './cards/Navbar';
import {ScreenContainer, MainContent} from '../styles/GlobalStyle'
import ComponentsTwo from './cards/ComponentsTwo';
import ComponentsThree from './cards/ComponentsThree';
import { linearIcons } from '../styles/utils';
// import Sidebar from "components/Sidebar";
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Metrics from './cards/Metrics';
// import CircularProgress from '@material-ui/core/CircularProgress';

const LogoutButton = styled.button`
  position: fixed;
  bottom: 24px;
  right: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const Profile = ({ handleLogout  }) => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);

  // setIsLoading(true);
  const userData = JSON.parse(localStorage.getItem('userData'));
  console.log("userData", userData)

  // const userData = JSON.parse(localStorage.getItem('foundUser'));
  // setIsLoading(false);
  // const foundUser = JSON.parse(localStorage.getItem('foundUser'));
  // console.log(foundUser)

  const handleLogoutClick = () => {
    handleLogout();
    navigate('/signin');
  };

  return (
      <>
      <ScreenContainer>
        {/* <Sidebar /> */}
         <MainContent style={{marginTop: '10px'}}>
         <div style={{ height: '60px', width: '100%', display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: '1em', padding: '0.1em 0', margin: '0', color: 'black', fontFamily: 'Century Gothic' }}>
              {userData.name}
            </div>
          </div>

        <div style={{marginBottom: '30px'}}>



              <Navbar defColor={linearIcons} userData={userData}/>
              <ComponentsTwo
                linearIcons={linearIcons}
                userData={userData}
                // onLogout={onLogout}
              />
              <ComponentsThree
              linearIcons={linearIcons}
              userData={userData}
            />
              </div>
          <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>
        </MainContent>
      </ScreenContainer>
      </>

    );
};
export default Profile;

const res = {
    "status": "success",
    "data": {
        "summary": [
            {
                "YEARS": "2022",
                "MEAN BALANCE": "27,252.81",
                "MEAN DEBIT COUNT": "204.62",
                "MEAN CREDIT COUNT": " 27,252.81 ",
                "MEAN DEBIT VALUE": "27,252.81",
                "MEAN CREDIT VALUE": "27,252.81",
                "TOTAL CREDIT": "27,252.81",
                "TOTAL DEBIT": "27,252.81"
            },
            {
                "YEARS": "2021",
                "MEAN BALANCE": "27,252.81",
                "MEAN DEBIT COUNT": "204.62",
                "MEAN CREDIT COUNT": " 27,252.81 ",
                "MEAN DEBIT VALUE": "27,252.81",
                "MEAN CREDIT VALUE": "27,252.81",
                "TOTAL CREDIT": "27,252.81",
                "TOTAL DEBIT": "27,252.81"
            },
        ]
    }
}
export default res;
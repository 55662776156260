import React, { useState } from 'react';
import styled from 'styled-components';
import Description from './Description';
import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Message from './Message';
// import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
// import MessageArray from './MessageArray';
// import { StyledInput, Tooltip } from 'antd';
import { Tooltip, Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
// import PhoneStyledInput from './PhoneStyledInput';

// import {ScreenContainer} from '../styles/GlobalStyle'



// export const ScreenContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   // bottom: 0;
//   background: linear-gradient(to right, #4776e6, #8e54e9);
//   // overflow-y: auto;
//   // overflow-x: hidden;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

export const ScreenContainer = styled.div`
  // display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #4776e6, #8e54e9);
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  // height: ${(props) => props.height || "100vh"};
`;


const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // position: relative;
  margin: 30px;
  // top: 30px;
  // right: 30px;
  // left: 500px;



  // flex-direction: column;
  height: 820px;
  // height: 90vh;
  // width: 90%;
  // height: 600px;
  width: 900px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  // background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;





















// export const ScreenContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   // width: 100vw;
//   // position: absolute;
//   // background: linear-gradient(to right, #4776e6, #8e54e9);
// `;



// const SignUpContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   top: 30px;
//   right: 30px;
//   height: 600px;
//   width: 900px;
//   padding: 20px;
//   // background-color: black; /* Add this line */
//   border-radius: 5px;
//   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
// `;


const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
`;

// const Description = styled.input`
//   width: 100%;
//   height: 100px;
//   padding: 10px;
//   margin-bottom: 10px;
//   border: none;
//   border-radius: 5px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
// `;



const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  // appearance: none;


  /* scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }


  // background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
  // background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - .5em) .5em;
  // background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  // background-repeat: no-repeat;
  // &:hover {
  //   background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
  //   background-position: calc(100% - 15px) 0.5em, calc(100% - 20px) 0.5em, calc(100% - .5em) .5em;
  //   background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  //   background-repeat: no-repeat;
  // }
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #FC4D02;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

// const ErrorMsg = styled.p`
//   color: red;
//   margin-top: 5px;
// `;

const states = [
  'Abia',
  'Adamawa',
  'Akwa Ibom',
  'Anambra',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Edo',
  'Ekiti',
  'Enugu',
  'FCT',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara'
];

// industries = [
// 'Agriculture',
// 'Oil and gas',
// 'Mining',
// 'Manufacturing',
// 'Telecommunications',
// 'Banking and finance',
// 'Construction',
// 'Healthcare',
// 'Education',
// 'Hospitality and tourism',
// 'Transportation and logistics',
// 'Media and entertainment',
// 'Retail and wholesale trade',
// 'Real estate',
// 'Power and energy',
// 'Information technology'
// ]

const industries = [
'Agriculture',
'Oil and gas',
'Mining',
'Manufacturing',
'Telecommunications',
'Banking and finance',
'Construction',
'Healthcare',
'Education',
'Hospitality and tourism',
'Transportation and logistics',
'Media and entertainment',
'Retail and wholesale trade',
'Real estate',
'Power and energy',
'Information technology',
'Textile and fashion',
'Chemicals and plastics',
'Agro-processing and food production',
'Environmental services',
'Insurance',
'Legal services',
'Advertising and marketing',
'Public administration',
'Non-profit and social services',
'Sports and fitness',
'Creative arts and design',
'Pharmaceuticals',
'Aerospace and defense',
'Automotive',
'Aviation',
'Biotechnology',
'Consumer goods',
'Education technology',
'Electronics',
'Energy technology',
'Engineering and construction',
'Financial technology',
'Food and beverage',
'Gaming and gambling',
'Health technology',
'Industrial machinery and equipment',
'Internet and software',
'Luxury goods',
'Medical devices and supplies',
'Metals and mining',
'Paper and packaging',
'Renewable energy',
'Security and surveillance',
'Social media',
'Space technology',
'Sustainability and clean technology',
'Travel technology'
]


const FileStyledInput = styled.input`
  display: none;
`;

const Label = styled.label`
  width: 100px;
  padding: 10px;
  // margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

// const SuccessMessage = styled.div`
//   color: green;
// `;

// const ErrorMessage = styled.div`
//   color: red;
// `;

// const MessageContainer = styled.div`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 9999;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   width: 400px;
//   height: 200px;
//   border: 1px solid black;
//   border-radius: 5px;
// `;

// const MessageTitle = styled.h1`
//   font-size: 1.5rem;
// `;

// const MessageText = styled.p`
//   font-size: 1rem;
//   margin-top: 20px;
// `;

// const CloseButton = styled.button`
//   margin-top: 20px;
//   padding: 10px 20px;
//   background-color: #ccc;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   &:hover {
//     background-color: #999;
//   }
// `;

function SignUp({ onLogin }) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [websiteurl, setWebsiteurl] = useState('');
  const [industry, setIndustry] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [description, setDescription] = useState('');
  const [zipcode, setZipcode] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [errorPasswordMessage, setPasswordErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['csrftoken']);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Render password suffix with eye icon
  const passwordSuffix = (
    <Tooltip title={passwordVisible ? 'Hide password' : 'Show password'}>
      <span onClick={togglePasswordVisibility}>
        {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </span>
    </Tooltip>
  );

  const handleFileStyledInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // async function handleSubmit(event) {
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password1 !== password2) {
      setShowPasswordErrorMessage(true);
      setPasswordErrorMessage('Passwords do not match. Please, ensure they do.');
      return;
    }

    async function fetchCsrfToken() {
      const response = await fetch('http://51.81.60.92:7006/login_system/get-csrf-token/');
      // const response = await fetch('https://api.lenda.prime-pay.africa/login_system/get-csrf-token/');
      const data = await response.json();
      return data.csrfToken;
    }

    async function signUp(
      name,
      email,
      password1,
      description,
      websiteurl,
      phonenumber,
      industry,
      address,
      city,
      state,
      zipcode,
      selectedFile
      ) {
      const csrfToken = await fetchCsrfToken(); // retrieve the CSRF token

      const reader = new FileReader();
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('password', password1);
      formData.append('address', address);
      formData.append('description', description);
      formData.append('website_url', websiteurl);
      formData.append('industry', industry);
      formData.append('phone_number', phonenumber);
      formData.append('city', city);
      formData.append('state', state);
      formData.append('zip_code', zipcode);
      formData.append('icon', selectedFile);

      // console.log("selectedFile", selectedFile)

      if (csrfToken !== undefined) {
        try {
          const response = await fetch('https://api.lenda.prime-pay.africa/login_system/admin-user/sign-up/', {
            method: 'POST',
            headers: {
              'X-CSRFToken': csrfToken,
            },
            body: formData
          });
          console.log(response);
          if (response.status === 201) {
            setShowMessage(true);
            setName('');
            setEmail('');
            setPassword1('');
            setPassword2('');
            setWebsiteurl('');
            setIndustry('');
            setPhonenumber('');
            setAddress('');
            setCity('');
            setState('');
            setDescription('');
            setZipcode('');
            setSelectedFile('');
          } else {
            setShowErrorMessage(true);
            const responseData = await response.json();
            console.log(responseData);
            setErrorMessage(JSON.stringify(responseData));

            if ((responseData.message) && (responseData.message.admin)) {

              if (responseData.message.admin.name) {
                setErrorMessage(responseData.message.admin.name);
              }

              if (responseData.message.admin) {
                setErrorMessage(responseData.message.admin);
              }

            }
          }
        } catch (error) {
          console.log(error);
          // setErrorMessage(JSON.stringify(error));
          setErrorMessage('An error occurred while submitting the form. Contact the administrator.');
          setShowErrorMessage(true)
        }
      } else {
        console.log('csrftoken cookie not found');
      }
    }

    signUp(
      name,
      email,
      password1,
      description,
      websiteurl,
      phonenumber,
      industry,
      address,
      city,
      state,
      zipcode,
      selectedFile
      );

  }

  return (
      <ScreenContainer>
        <SignUpContainer>
            <form onSubmit={handleSubmit} style={{height: '95%', width: '95%', borderRadius: '12px'}}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Title>Company Sign Up</Title></div>

                  <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%'}}>
                    <StyledInput
                      type="text"
                      placeholder="Company name"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      required
                    />
                    <StyledInput
                        type="email"
                        placeholder="Admin's Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                  </div>
                  <div style={{ display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%' }}>
                    <Input.Password
                      placeholder="Password"
                      value={password1}
                      onChange={(event) => setPassword1(event.target.value)}

                      required
                      suffix={passwordSuffix}
                      style={{width: '100%', padding: '10px', marginBottom: '10px',
                        border: 'none', borderRadius: '5px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)'}}
                    />
                    <Input.Password
                      placeholder="Confirm Password"
                      value={password2}
                      onChange={(event) => setPassword2(event.target.value)}
                      required
                      suffix={passwordSuffix}
                      style={{width: '100%', padding: '10px', marginBottom: '10px',
                        border: 'none', borderRadius: '5px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)'}}
                    />
                  </div>

                  <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%'}}>
                  <StyledInput
                      type="text"
                      placeholder="Phone number"
                      value={phonenumber}
                      onChange={(event) => setPhonenumber(event.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="Company's website"
                      value={websiteurl}
                      onChange={(event) => setWebsiteurl(event.target.value)}
                      required
                    />
                  </div>

                  <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%'}}>
                    <StyledInput
                      type="text"
                      placeholder="Company's address"
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="City"
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                      required
                    />
                  </div>
                  <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%'}}>
                  <Select
                      value={state}
                      onChange={(event) => setState(event.target.value)}
                      style={{backgroundColor: '#f5f5f5', width: '103%'}}
                      required
                    >
                      <option value="" disabled selected>Select a state</option>
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <StyledInput
                      type="text"
                      placeholder="Zip code"
                      value={zipcode}
                      onChange={(event) => setZipcode(event.target.value)}
                    />
                  </div>

                  <div style={{display: 'flex', gridTemplateColumns: 'repeat(2, 49%)', gridGap: '2%'}}>
                    <Select
                    value={industry}
                    onChange={(event) => setIndustry(event.target.value)}
                    style={{backgroundColor: '#f5f5f5', width: '103%'}}
                    required
                  >
                    <option value="" disabled selected >Select an industry</option>
                    {industries.map((industry) => (
                      <option key={industry} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </Select>
                  </div>

                    <Description
                        label="Company description"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />

                  <div style={{width: '100%', height: '180px',
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center',
                    }}>
                      <div  style={{width: '300px', height: '90%',
                      display: 'flex', justifyContent: 'center',
                      alignItems: 'center', borderRadius: '6px',
                      backgroundColor: '#fff',
                      }}>
                        <Label htmlFor="file-input" style={{width: '250px', display: 'flex', justifyContent: 'center',
                          alignItems: 'center', fontSize: '.8em', textAlign: 'center'}}>
                          {selectedFile ? selectedFile.name : "Add company's icon with transparent background"}
                      </Label>

                      <FileStyledInput
                        id="file-input"
                        type="file"
                        onChange={handleFileStyledInputChange}
                        style={{backgroundColor: 'linear-gradient(to right, #4776e6, #8e54e9)'}}
                      />
                    </div>

                  </div>

                  <div style={{marginTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button type="submit" style={{backgroundColor: '#fff', color: 'black',width: '400px', justifyContent: 'center'}}>Register</Button>

                  </div>

                  {showErrorMessage && (
                    <div style={{position: 'relative', top: '10px'}}>
                      <Message
                      title="Error"
                      text={errorMessage}
                      onClose={() => setShowErrorMessage(false)}
                      width="500px" height="300px"
                    />
                    </div>
                  )}

                  {showMessage && (
                    <div style={{position: 'relative', top: '10px'}}><Message
                      title="Registration Successful"
                      text="Thank you for registering! Please, proceed to the sign in to proceed."
                      onClose={() => setShowMessage(false)}
                      width="500px" height="300px"
                    />
                    </div>
                  )}



            </form>




        </SignUpContainer>
      </ScreenContainer>
      );

}

export default SignUp;
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const DescriptionLabel = styled.label`
  margin-bottom: 5px;
  font-size: 1.2rem;
`;

const DescriptionTextarea = styled.textarea`
  width: 90%;
  height: 100px;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%)
  margin: 0;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  resize: vertical; /* allow the textarea to expand vertically */
`;

const Description = ({ label, value, onChange }) => {
  return (
    <DescriptionContainer>
      <DescriptionLabel>{label}</DescriptionLabel>
      <DescriptionTextarea value={value} onChange={onChange} />
    </DescriptionContainer>
  );
};

export default Description;



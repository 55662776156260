import styled from 'styled-components';

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const DescriptionLabel = styled.label`
  margin-bottom: 5px;
  font-size: 1.2rem;
`;

const DescriptionTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 0;
  border: none;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  resize: vertical; /* allow the textarea to expand vertically */
`;

const Description = ({ label, value, onChange }) => {
  return (
    <DescriptionContainer>
      <DescriptionLabel>{label}</DescriptionLabel>
      <DescriptionTextarea value={value} onChange={onChange} />
    </DescriptionContainer>
  );
};

export default Description;



// import React from 'react';
// import styled from 'styled-components';

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 20px;
// `;

// const Label = styled.label`
//   font-size: 1rem;
// //   font-weight: bold;
//   margin-bottom: 5px;
// `;

// const DescriptionInput = styled.textarea`
//   width: 100%;
//   height: 100px;
//   padding: 10px;
//   margin-bottom: 10px;
//   forn-family: Roboto;
//   border: none;
//   border-radius: 5px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
// `;

// const Description = ({ value, onChange }) => {
//   return (
//     <Container>
//       <Label htmlFor="description">Company description</Label>
//       <DescriptionInput
//         id="description"
//         type="text"
//         value={value}
//         onChange={onChange}
//         placeholder="Enter company's description"
//       />
//     </Container>
//   );
// };

// export default Description;

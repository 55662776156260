import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { linearIcons } from '../styles/utils';

const NewButton = ({ onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (menuItem) => {
    setAnchorEl(null);
    onSelect(menuItem);
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: `${linearIcons}`,
          // backgroundImage: `${linearIcons}`,
          height: '40px',
          marginLeft: '3px',
          // add color property to change the color of text
          color: 'white',
          textTransform: 'none' // override the default textTransform value
        }}
        // aria-controls="simple-menu"
        aria-controls="menu-list" // change the value of aria-controls attribute
        aria-haspopup="true"
        onClick={handleClick}
      >
        Select Payslip Type
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose('Police (IPPIS)')}>Police (IPPIS)</MenuItem>
        <MenuItem onClick={() => handleClose('IPPIS')}>IPPIS</MenuItem>
      </Menu>
    </>
  );
};

export default NewButton;

import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #f2f2f2;
`;

const TableHeading = styled.th`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     // background-color: #f2f2f2;
//   }
// `;

function SummaryTableNewTransposeTwo({res}) {
  // console.log(res)
  let yr1Bal, formattedDate, yr1CreDeb, startFormattedDate;
  if ('YearInterval1' in res.data.balanceInterval) {
      yr1Bal = res.data.balanceInterval.YearInterval1.filter(item => item.Date === 'Total')[0];
      formattedDate = res.data.balanceInterval.YearInterval1[res.data.balanceInterval.YearInterval1.length - 2]['Date'];
      startFormattedDate = res.data.balanceInterval.YearInterval1[0]['Date'];
      yr1CreDeb = res.data.creditDebitInterval.YearInterval1.filter(item => item.Date === "Total")[0];
  }

  let yr2Bal, yr2CreDeb, formattedDate2, startFormattedDate2;
  if ('YearInterval2' in res.data.balanceInterval) {
      yr2Bal = res.data.balanceInterval.YearInterval2.filter(item => item.Date === "Total")[0];
      yr2CreDeb = res.data.creditDebitInterval.YearInterval2.filter(item => item.Date === "Total")[0];
      formattedDate2 = res.data.balanceInterval.YearInterval2[res.data.balanceInterval.YearInterval2.length - 2]['Date'];
      startFormattedDate2 = res.data.balanceInterval.YearInterval2[0]['Date'];
  }

  return (
    <TableWrapper>
    <TableHead>
      <tr>
        <TableHeading></TableHeading>
        {'YearInterval1' in res.data.balanceInterval && <TableHeading>Interval One</TableHeading>}
        {'YearInterval2' in res.data.balanceInterval && <TableHeading>Interval Two</TableHeading>}
        {/* {['Year One', 'Year Two'].map((year, index) => (
          <TableHeading key={index}>{year}</TableHeading>
        ))} */}
      </tr>
    </TableHead>
    <tbody>
      <tr>
        <TableData>Start Date</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{startFormattedDate}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{startFormattedDate2}</TableData>}
        {/* <TableData>{startFormattedDate}</TableData>
        <TableData>{startFormattedDate2}</TableData> */}
      </tr>
      <tr>
        <TableData>End Date</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{formattedDate}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{formattedDate2}</TableData>}
        {/* <TableData>{formattedDate}</TableData>
        <TableData>{formattedDate2}</TableData> */}
      </tr>
      <tr>
        <TableData>Mean Balance</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1Bal["Balance (Mean)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2Bal["Balance (Mean)"]}</TableData>}
        {/* <TableData>{yr1Bal["Balance (Mean)"]}</TableData>
        <TableData>{yr2Bal["Balance (Mean)"]}</TableData> */}
      </tr>
      <tr>
        <TableData>Debit Count</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Debit (Count)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Debit (Count)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Debit (Count)"]}</TableData>
        <TableData>{yr2CreDeb["Debit (Count)"]}</TableData> */}
      </tr>
      <tr>
        <TableData>Credit Count</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Credit (Count)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Credit (Count)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Credit (Count)"]}</TableData>
        <TableData>{yr2CreDeb["Credit (Count)"]}</TableData> */}
      </tr>
      <tr>
        <TableData>Mean Debit Value</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Debit (Mean)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Debit (Mean)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Debit (Mean)"]}</TableData>
        <TableData>{yr2CreDeb["Debit (Mean)"]}</TableData> */}
      </tr>
      <tr>
        <TableData>Mean Credit Value</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Credit (Mean)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Credit (Mean)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Credit (Mean)"]}</TableData>
        <TableData>{yr2CreDeb["Credit (Mean)"]}</TableData> */}
      </tr>
      <tr>
        <TableData>Total Credit</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Credit (Sum)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Credit (Sum)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Credit (Sum)"]}</TableData>
        <TableData>{yr2CreDeb["Credit (Sum)"]}</TableData> */}
    </tr>
      <tr>
        <TableData>Total Debit</TableData>
        {'YearInterval1' in res.data.balanceInterval && <TableData>{yr1CreDeb["Debit (Sum)"]}</TableData>}
        {'YearInterval2' in res.data.balanceInterval && <TableData>{yr2CreDeb["Debit (Sum)"]}</TableData>}
        {/* <TableData>{yr1CreDeb["Debit (Sum)"]}</TableData>
        <TableData>{yr2CreDeb["Debit (Sum)"]}</TableData> */}
    </tr>
</tbody>
</TableWrapper>
);
}


export default SummaryTableNewTransposeTwo;

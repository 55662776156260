import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import html2pdf from 'html2pdf.js';

import styled from 'styled-components';
// import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router-dom';
// import PayrollTable from './PayrollTable';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
// TableHead
import NewButton from './NewButton';
import {ScreenContainer, MainContent} from '../styles/GlobalStyle'
// import CircularProgress from '@material-ui/core/CircularProgress';
// import { linearIcons } from 'components/styles/GlobalStyle';
import { linearIcons } from '../styles/utils';

import { Lock as LockIcon } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import EnquiryTable from 'components/Payslip/EnquiryTable';
import Sidebar from "components/Sidebar";
import Description from './Description';
import CircularProgress from '@material-ui/core/CircularProgress';

const maskValue = (value) => {
  const maskedValue = value.replace(/[0-9]/g, '*'); // Replace all digits with asterisks
  return maskedValue;
};


// import {ScreenContainer} from '../styles/GlobalStyle'
// import html2pdf from 'html2pdf.js';
// import "./pdf.css";

const StyledTableContainer = styled(TableContainer)`
  && {
    box-shadow: none;
  }
`;


// const PayslipContainer = styled.div`
//   display: flex;
//   height: 100vh;
// `;

// const MainContent = styled.div`
//   flex-grow: 1;
//   // padding: 24px;
//   // margin-left: 270px;
//   // margin-right: 40px;
// `;

const LogoutButton = styled.button`
  position: fixed;
  bottom: 24px;
  right: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

// const TableWrapper = styled.div`
//   position: absolute;
//   top: 200px;
//   left: 340px;
//   right: 70px;
//   // bottom: -90px;
//   padding: 24px;
//   box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);
// `;
const TableWrapper = styled.div`
  width: 550px;
  // right: 70px;
  padding: 24px;
  margin-top: 40px;
  margin-bottom: 70px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);

  .MuiTable-root {
    box-shadow: none;
    border-collapse: collapse;
  }

  .MuiTableCell-root {
    border: none;
  }
`;


const NewTableWrapper = styled.div`
  min-width: 300px;
  padding: 24px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.35);

  .MuiTable-root {
    box-shadow: none;
    border-collapse: collapse;
  }

  .MuiTableCell-root {
    border: none;
  }
`;


// const Label = styled.label`
//   display: block;
//   font-size: 1.4rem;
//   margin-bottom: 1rem;
// `;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;

  ::placeholder {
    /* Add your placeholder styles here */
    color: #999; /* Change the color of the placeholder text */
    font-size: 14px;
    font-style: Century Gothic; /* Apply italic style to the placeholder text */
  }

`;

// backgroundImage: `${linearIcons}`,
const Button = styled.button`
  // background-image: ${linearIcons};
  background-color: ${linearIcons};
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-image: #2e8b57;
  }
`;
const BButton = styled.button`
  // background-image: ${linearIcons};
  background-color: ${linearIcons};
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-image: #2e8b57;
  }
`;

const data = [
  {
    id: 1,
    enquiryDate: "01/01/2023",
    enquirerName: "John Doe",
    enquirerPhone: "+1234567890",
    reason: "General enquiry",
  },
  {
    id: 2,
    enquiryDate: "01/02/2023",
    enquirerName: "Jane Doe",
    enquirerPhone: "+9876543210",
    reason: "Specific enquiry",
  },
  // Add more rows as needed
];


const keyMap = {
  EmployeeName: "Name",
  Ministry: "Ministry",
  PayrollNumber: "IPPIS Number (Police)",
  AccountNumber: "Account Number",
  BankName: "Bank",
  DateofFirstAppt: "Date of First Appointment",
  DateofBirth: "Date of Birth",
  Gender: "Gender",
  YearsRemaining: "Years Remaining in Service",
  Eligibility: "Eligibility",
  EligibleAmount: "Eligible Amount",
  Reason: "Reason",
  NegativePay: "NegativePay",
};

function getMinimum(data) {
  try{
    const lastThree = data.slice(0, 3); // Get the last three objects
    const amounts = lastThree.map(item => item.MaximumRepaymentAmount); // Extract MaximumRepaymentAmount values
    const filteredAmounts = amounts.filter(item => item !== null); // Remove null values
    const minimum = Math.min(...filteredAmounts); // Find the minimum value
    if (minimum === Infinity) {
      return null;
    }
    return minimum;
  } catch (error){
    console.error(error);
    return 0
  }
}

// const ErrorContainer = styled.div`
//   background-color: red;
//   color: white;
//   padding: 10px;
//   margin-bottom: 10px;
// `;

const ErrorContainer = styled.div`
  position: absolute;
  width: 406px;
  height: 208px;
  left: 50%;
  top: 34%;
  transform: translate(-50%, -50%);
  background: ${linearIcons};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorContent = styled.div`
  padding: 20px;
  text-align: center;
  color: white;
`;

const ErrorButton = styled.button`
  background-color: #ff0000;
  color: #ffffff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ErrorContainerr = ({ message, onClose }) => (
  <ErrorContainer>
    <ErrorContent>
      <p>{message}</p>
      <ErrorButton onClick={onClose}>Cancel</ErrorButton>
    </ErrorContent>
  </ErrorContainer>
);

const premiumEmails = [
  'admin@email.com',
  'Customerservice@prime-pay.africa',
  'Info@prime-pay.africa',
  'momojola@pagefinancials.com',
  'oagbede@prime-pay.africa',
  'abadaru@prime-pay.africa',
  'hmoyse@prime-pay.africa',
  'info@prime-pay.africa',
  // 'Info@prime-pay.africa',
];



const Payslip = ({ handleLogout }) => {
  const navigate = useNavigate();
    const [payrollData, setPayrollData] = useState(null);
    const [payrollNumber, setPayrollNumber] = useState('');
    const [netPay, setNetPay] = useState('');

    const [selectedOption, setSelectedOption] = useState(null);
    const [showFullInfo, setShowFullInfo] = useState(false);
    const [description, setDescription] = useState('');
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [subscription_status, setSubscriptionStatus] = useState('');

    const handleCloseError = () => {
      setError('');
    };


    // const hidevalue = [
    //   "EmployeeName"
    // ]

    const showkeys = [
      "Name",
      "PayrollNumber",
      "Eligibility",
      "Reason",
      "NegativePay",
  ];

    const hidevals = [
      "AccountNumber",
      "BankName",
      "Ministry",
      // "DateofFirstAppt",
      // "DateofBirth",
      // "Gender",
      // "Male",
      // "YearsRemaining",
      // "Eligibility",
      // "EligibleAmount"
  ];

    const ippishidevals = [
      "AccountNumber",
      "BankName",
      "Ministry",
      "DateofFirstAppt",
      "DateofBirth",
      "Gender",
      "Male",
      "YearsRemaining",
      "Eligibility",
      "Reason",
      "NegativePay",
      "EligibleAmount",
  ];



    // const [isLoading, setIsLoading] = useState(false);

    // const handleLogout = () => {
    //   onLogout();
    //   navigate('/signin');
    // };
    const handleLogoutClick = () => {
      handleLogout();
      navigate('/signin');
    };

    const userData = JSON.parse(localStorage.getItem('userData'));

    const handleUpgrade = () => {
      // console.log(userData.name)
      if (userData.name !== 'Admin') {
        setShowFullInfo(false)
        // setSubscriptionStatus('premium')
      } else {
        setShowFullInfo(true)
      }

    }




    useEffect(() => {
      if (premiumEmails.includes(userData.email)) {
        userData.subscription_status = 'premium';
        setSubscriptionStatus('premium');
        // console.log("I am here in if");
      } else {
        userData.subscription_status = 'freemium';
        setSubscriptionStatus('freemium');
        // console.log("I am here in else if");
      }
    }, [userData.email]);



    const handleSearch = () => {







      const jsonpayload = {
        id: userData.id,
        email: userData.email,
        reason_for_enquiry: description,
        subscription_status: subscriptionStatus,
      };


      if (netPay !== "") {
        jsonpayload.netPay = netPay;
      }

      if (!jsonpayload.reason_for_enquiry) {
        setError('Reason for enquiry is required.');
        return;
      }

      if (selectedOption === 'Police (IPPIS)'){
        setIsLoading(true);
        // fetch(`https://api.lenda.prime-pay.africa/payslip/payroll/search/police/${payrollNumber}`, {
        fetch(`http://51.81.60.92:7006/payslip/payroll/search/police/${payrollNumber}`, {
          method: 'POST',
          body: JSON.stringify(jsonpayload),
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => response.json())
          .then((data) => {
            setPayrollData(data);
            setIsLoading(false);

            if ('error' in data){
              setError(data.error);
            }

          })
          .catch((error) => console.error(error));
        }
      else if (selectedOption === 'IPPIS'){
        setIsLoading(true);
        fetch(`http://51.81.60.92:7006/payslip/payroll/search/ippis/${payrollNumber}`,
        // fetch(`https://api.lenda.prime-pay.africa/payslip/payroll/search/ippis/${payrollNumber}`,
          {
            method: 'POST',
            body: JSON.stringify(jsonpayload),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setPayrollData(data);
            setIsLoading(false);
            if ('error' in data){
              setError(data.error);
            }
            console.log("data", data)
          })
          .catch((error) => console.error(error));
          // console.log(data)
      }
      // setIsLoading(false);
      setShowFullInfo(false)
    };

    function handleDownloadPdf() {
      const input = document.getElementById("pdf-wrapper");

      html2canvas(input, { height: input.scrollHeight }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: [window.innerWidth, window.innerHeight],
        });
        pdf.setFontSize(40);
        pdf.setTextColor("orange");
        pdf.text("Page Financials", 70, 100);
        pdf.addImage(imgData, "JPEG", 10, 10, pdf.internal.pageSize.getWidth() - 20, pdf.internal.pageSize.getHeight() - 20);
        pdf.save("Page Financials Payslip.pdf");

        // Remove the "Download PDF" button
        const downloadBtn = document.getElementById("download-pdf");
        downloadBtn.style.display = "none";
      });
    }

  return (
    < >
      <ScreenContainer>
      <Sidebar/>

          <MainContent>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1 style={{ margin: 0, minHeight: '60px' }}>Generate Payslip</h1>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Input
                  id="payrollNumber"
                  type="text"
                  style={{ width: '200px', marginRight: '2px', height: '24px' }}
                  value={payrollNumber}
                  placeholder="Insert Payroll Number"
                  onChange={(e) => setPayrollNumber(e.target.value)}
                />
                {
                  subscriptionStatus === 'freemium' &&
                  <Input
                  id="netPay"
                  type="text"
                  style={{ width: '200px', marginRight: '2px', height: '24px' }}
                  value={netPay}
                  placeholder="Insert Net Pay"
                  onChange={(e) => setNetPay(e.target.value)}
                />
                }

                <Button
                  onClick={handleSearch}
                  onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
                  style={{ fontSize: '14px', padding: '5px 10px', height: '40px'}}
                >
                  Generate
                </Button>

                {/* {payrollData && <button onClick={() => setShowFullInfo(true)}>Upgrade Now</button>} */}
                <NewButton onSelect={(menuItem) => setSelectedOption(menuItem)} />


              {payrollData &&<BButton
                onClick={handleDownloadPdf}
                style={{backgroundColor: `${linearIcons}`, height: '40px', marginLeft: "3px", fontSize: '14px',}}
              >
                Download Payslips
              </BButton>}
              <Button
                  onClick={handleUpgrade}
                  style={{ fontSize: '14px', marginLeft: '5px', padding: '5px 10px', height: '40px'}}
                >
                  Upgrade Now
                </Button>
            </div>
          </div>
          <Description
              label="Reason for Enquiry"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

        {/* {error && <ErrorContainer>{error}</ErrorContainer>} */}

        {error && <ErrorContainerr message={error} onClose={handleCloseError} />}

      {payrollData && payrollData.summary && (
        <div id="pdf-wrapper">
          <TableWrapper style={{ backgroundColor: 'white' }}>
            <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", color: 'black' }}>
              PAYSLIP DATA
            </h2>
            <StyledTableContainer component={Paper} style={{ width: "35rem" }}>
              <Table>
                <TableBody>
                  {Object.entries(payrollData.summary).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell style={{ width: '15rem' }}>{keyMap[key]}</TableCell>
                      <TableCell>
                        {value !== null ? value : ''} {/* Show empty string if value is null */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            {payrollData.summary.NegativePay && payrollData.summary.NegativePay !== 'null' && Array.isArray(payrollData.summary.NegativePay) && payrollData.summary.NegativePay.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                <h3>Negative Pay Dates</h3>
                <p>
                  {payrollData.summary.NegativePay.map(date => new Date(date).toLocaleDateString()).join(', ')}
                </p>
              </div>
            )}
          </TableWrapper>
        </div>
      )}
         {payrollData && payrollData.data && (
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "1rem",
                gridRowGap: "1rem",
              }}>
                {payrollData.data.map((data) => (
                      <NewTableWrapper style={{ marginBottom: "2rem", width: "400px", backgroundColor: 'white' }}>
                        <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", color: 'black'}}>
                          {data.Month} PAYSLIP
                        </h2>
                        <StyledTableContainer component={Paper}>
                          <Table>
                            <TableBody>
                              <TableRow key="FinalNetSalary" style={{ height: "10px" }}>
                                <h3>Net Pay</h3>
                                <TableCell align="right">{Number(data.FinalNetSalary).toLocaleString()}</TableCell>
                              </TableRow>
                              {data.EarningsDTLCol_RO && (
                                <React.Fragment>
                                  <TableRow key="EarningsDTLCol_RO" style={{ height: "10px" }}>
                                    <h3>Income Breakdown</h3>
                                    <TableCell></TableCell>
                                  </TableRow>
                                  {data.EarningsDTLCol_RO.split("#").map((item) => (
                                    <TableRow key={item.split("|")[0]} style={{ height: "10px" }}>
                                      <TableCell>{item.split("|")[0]}</TableCell>
                                      <TableCell align="right">{Number(item.split("|")[1]).toLocaleString()}</TableCell>
                                    </TableRow>
                                  ))}
                                </React.Fragment>
                              )}
                              {data.DeductionsDTLCol_RO && (
                                <React.Fragment>
                                  <TableRow key="DeductionsDTLCol_RO" style={{ height: "10px" }}>
                                    <h3>Deductions</h3>
                                    <TableCell></TableCell>
                                  </TableRow>
                                  {data.DeductionsDTLCol_RO.split("#").map((item) => (
                                    <TableRow key={item.split("|")[0]} style={{ height: "10px" }}>
                                      <TableCell>{item.split("|")[0]}</TableCell>
                                      <TableCell align="right">{Number(item.split("|")[1]).toLocaleString()}</TableCell>
                                    </TableRow>
                                  ))}
                                </React.Fragment>
                              )}
                            </TableBody>
                          </Table>
                        </StyledTableContainer>
                      </NewTableWrapper>
                    ))}

              </div>
            </div>
          )}


          {payrollData && payrollData.search_history && <EnquiryTable searchHistory={payrollData.search_history}/>}
            <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>


          </MainContent>
      </ScreenContainer>
    </>

  );

};
export default Payslip;

import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;

    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;


  }


  // overflow-y: hidden;
  // /* Enable scrollbar styling on Edge */
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // /* Scrollbar width */
  // /* Webkit-based browsers */


  // &::-webkit-scrollbar {
  //   width: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #F5F5F5;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: #999;
  //   border-radius: 20px;
  //   border: 1px solid #F5F5F5;
  // }

  // /* Firefox */
  // scrollbar-width: thin;
  // scrollbar-color: #999 #F5F5F5;

  // & {
  //   scrollbar-color: #999 #F5F5F5;
  //   scrollbar-width: thin;
  // }

`;

// export const MainContent = styled.div`
//   flex-grow: 1;
//   padding: 24px;
//   margin-top: 20px;
// `;
// export const ScreenContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   position: absolute;
//   right: 0;
//   left: 300px;
// `;


export const MainContent = styled.div`
  flex-grow: 1;
  margin: 40px 10px;
  height: 100%;
  // overflow-y: hidden;
`;

// export const MainContent = styled.div`
//   flex-grow: 1;
//   width: calc(100vw - 280px);
//   margin-top: 24px;
//   margin-bottom: 100px;
//   display: flex;
//   flex-direction: column;
//   gap: 10px;
//   background: black;

// `;

export const ScreenContainer = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;

  left: 250px;
  // left: 250px;
  // margin-left: 180px;
  // overflow-y: hidden;
  // overflow-x: hidden;
  // bottom: 30px;


  overflow-y: auto;
  /* Enable scrollbar styling on Edge */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Scrollbar width */
  /* Webkit-based browsers */


  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999;
    border-radius: 20px;
    border: 1px solid #F5F5F5;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #999 #F5F5F5;

  & {
    scrollbar-color: #999 #F5F5F5;
    scrollbar-width: thin;
  }
`;

export const linearIcons = 'linear-gradient(157deg, rgba(21,12,58,1) 0%, rgba(37,150,190,1) 48%, rgba(0,0,0,1) 100%, rgba(76,97,255,1) 100%)';
// const HomeContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   }
// `;

// const ProfileContainer = styled.div`
//   display: flex;
//   height: 100vh;
// `;

// const SalesContainer = styled.div`
//   display: flex;
//   height: 100vh;
// `;

// const UnderwritersContainer = styled.div`
//   display: flex;
//   height: 100vh;
// `;
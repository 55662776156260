import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #f2f2f2;
`;

const TableHeading = styled.th`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

// const TableRow = styled.tr`
//   &:nth-child(even) {
//     // background-color: #f2f2f2;
//   }
// `;

// function AmountTable({value0, value00, value1, value2}) {
function AmountTable({data}) {
  let { value, value5, maxAmount, minAmount } = data
    // value0={calculateamounts(response).value}
    // value00={calculateamounts(response).value5}
    // value1={calculateamounts(response).maxAmount}
    // value2={calculateamounts(response).minAmount}
    return (
        <TableWrapper>
        <TableHead>
          <tr>
            <TableHeading>Threshold</TableHeading>
            <TableHeading>Value</TableHeading>
          </tr>
        </TableHead>
        <tbody>
          <tr>
            <TableData>Three Months Average Balance</TableData>
            <TableData>{value}</TableData>
          </tr>
          <tr>
            <TableData>Five Months Average Balance</TableData>
            <TableData>{value5}</TableData>
          </tr>
          <tr>
            <TableData>Maximum Threshold</TableData>
            <TableData>{maxAmount}</TableData>
          </tr>
          <tr>
            <TableData>Minimum Threshold</TableData>
            <TableData>{minAmount}</TableData>
          </tr>
        </tbody>
      </TableWrapper>
    );
}


export default AmountTable;


import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import SignInPage from "./components/SignInPage";
import Profile from "./components/Profilee";
import Payslip from "./components/Payslip";
import BusinessLoans from "./components/BusinessLoans";
import Sidebar from "./components/Sidebar";
import SignUp from "./components/SignUp";
import './Loading.css'; // Import the CSS file for custom styles
import SoftwareLoading from './SoftwareLoading';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");

  // const handleLogin = () => {
  //   // Set isLoggedIn to true and store token
  //   setIsLoggedIn(true);
  //   localStorage.setItem("isLoggedIn", "true");
  // };

  // const handleLogout = () => {
  //   // Set isLoggedIn to false and remove token
  //   setIsLoggedIn(false);
  //   localStorage.removeItem("isLoggedIn");
  // };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div style={{ display: "flex", height: "100%" }}>
        {isLoggedIn && <Sidebar />}
        <Routes style={{ flexGrow: 1 }}>
          <Route path="/signin" element={<SignInPage handleLogin={handleLogin} />} />
          <Route path="/profile" element={isLoggedIn ? <Profile handleLogout={handleLogout} /> : <Navigate to="/signin" replace />} />
          <Route path="/payslip" element={isLoggedIn ? <Payslip handleLogout={handleLogout} /> : <Navigate to="/signin" replace />} />
          <Route path="/business-loans" element={isLoggedIn ? <BusinessLoans handleLogout={handleLogout} /> : <Navigate to="/signin" replace />} />
          <Route path="/signup" element={<SignUp to="/signup" replace />} />

          <Route path="*" element={<Navigate to="/signin" replace />} />
          {/* <Route path="*" element={<Navigate to="/signin" replace />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

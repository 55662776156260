import React, { useState } from 'react';
import SummaryTableNew from './SummaryTableNewTranspose';
import SummaryTableNewTransposeTwo from './SummaryTableNewTransposeTwo';
import AmountTable from './AmountTable';
import MyTable from './MyTable';
import MySecondTable from './MySecondTable';
import DescriptionTable from './DescriptionTable';
import './Tables.css';
import BusinessLoanTemplate from './BusinessLoanTemplate.csv'
import res from './SummaryData';
import csvtojson from 'csvtojson';
import { read, utils } from 'xlsx';
// import { linearIcons } from 'components/styles/GlobalStyle';
import { linearIcons } from '../styles/utils';
import styled from 'styled-components';

// const Wrapper = styled.div`
//   text-align: center;
//   margin-top: 20px;
// `;

// const UploadInput = styled.input`
//   display: inline-block;
//   position: relative;
//   width: 19rem;
//   height: 5rem;
//   margin-right: 1rem;
//   border-radius: 3px;
//   overflow: hidden;
//   cursor: pointer;

//   &::before {
//     content: 'Select a file';
//     display: inline-block;
//     color: white;
//     background-color: #4caf50;
//     border-radius: 3px;
//     padding: 0.5rem 1rem;
//     font-size: 1rem;
//     font-weight: bold;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
//   }

//   &::after {
//     content: '${props => props.value ? props.value.replace(/^.*[\\/]/, '') : 'No file chosen'}';
//     display: inline-block;
//     color: black; /* Set the font color to black */
//     background-color: white;
//     border-radius: 3px;
//     padding: 0.5rem 1rem;
//     margin-left: 1rem;
//     font-size: 1rem;
//   }

//   &:hover::before {
//     background-color: #388e3c;
//   }

//   &:focus::before {
//     outline: none;
//     box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.3);
//   }

//   &:disabled::before {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }

//   input {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     opacity: 0;
//     z-index: -1;
//     cursor: pointer;
//     onChange: ${props => props.onChange};
//   }
// `;

// const linearIcons = 'linear-gradient(157deg, rgba(21,12,58,1) 0%, rgba(37,150,190,1) 48%, rgba(0,0,0,1) 100%, rgba(76,97,255,1) 100%)';

// const Button = styled.button`
//   width: 100%;
//   padding: 6%;
//   margin-bottom: 16px;
//   background-image: ${linearIcons};
//   color: #fff;
//   border: none;
//   border-radius: 8px;
//   font-size: 100%;
//   cursor: pointer;


// //   &:focus{
// //     background: ash;
// //     color: white;
// //     cursor: default;
// // }


// `;
// const linearIcons = 'linear-gradient(157deg, rgba(21,12,58,1) 0%, rgba(37,150,190,1) 48%, rgba(0,0,0,1) 100%, rgba(76,97,255,1) 100%)';

const UploadButton = styled.button`
  width: 9rem;
  height: 3rem;
  background-color: ${linearIcons};
  // background-image: ${linearIcons};
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-image: #eb34c0;
  }
`;

const DownloadButton = styled.a`
  width: 10rem;
  height: 3rem;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

// const Title = styled.h3`
//   font-family: 'Roboto', sans-serif;
//   margin-bottom: 1rem;
// `;

// const UploadForm = styled.form`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const Input = styled.input`
//   width: 19rem;
//   height: 5rem;
//   color: black;
//   padding: 1rem;
// `;

const InputButton = styled.input`
  width: 19rem;
  height: 1rem;
  padding: 1rem;
  background-color: #fff;
  color: black;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Aligner = styled.div`
  display: flex;
`;
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function calculateamounts(response) {
  const val = response.data.balanceLastOneYear.map(item => item['SMA(3) of Balance (Mean)']).slice(-2,-1)[0].replace(/,/g, '')
  const value5 = response.data.balanceLastOneYear.map(item => item['SMA(5) of Balance (Mean)']).slice(-2,-1)[0]
  const maxAmount = formatNumber(parseFloat(val * 5).toFixed(2));
  const minAmount = formatNumber(parseFloat(val * 3).toFixed(2));
  const value = formatNumber(parseFloat(val).toFixed(2));
    return { value, value5, maxAmount, minAmount };
}

function FileUploader() {
  const [file, setFile] = useState(null);
  // const [json, setJson] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    setLoading(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const fileData = e.target.result;
      let jsonData;
      if (file.name.endsWith('.csv')) {
        jsonData = await csvtojson({noheader: false, flatKeys: false}).fromString(fileData);
      } else if (file.name.endsWith('.xlsx')) {
        const workbook = read(fileData, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvString = utils.sheet_to_csv(worksheet);
        jsonData = await csvtojson().fromString(csvString.trim());
      } else {
        alert('Invalid file format. Please select a CSV or XLSX file.');
        setLoading(false);
        return;
      }

      jsonData.forEach((obj) => {
        const newHeaders = {};
        for (const header in obj) {
          const newHeader = header.replace(/[^a-zA-Z]/g, '');
          newHeaders[newHeader] = obj[header];
        }
        Object.assign(obj, newHeaders);
      });



      // Remove non-string characters from column names
      const formattedData = jsonData.map((obj) => {
        const formattedObj = {};
        for (const key in obj) {
          const formattedKey = key.replace(/[^a-zA-Z]/g, '');
          formattedObj[formattedKey] = obj[key];
        }
        return formattedObj;
      });

      try {
        const res = await fetch('http://192.99.217.35:7002/businessloanapi/business_loan/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: formattedData }),
        });

        const jsonRes = await res.json();
        setResponse(jsonRes);
      } catch (error) {
        alert(
          'Please try again or check that your file is in the right format before uploading. Kindly download the above template for guidance on how to restructure your file. You may also want to check if you are connected to the internet.'
        );
      }

      setLoading(false);
    };

    reader.readAsBinaryString(file);
  };


  return (
    <div styles={{marginTop: "20px"}}>
      {/* {!response  && */}
      <>
        {/* <Wrapper>
          <Title>UPLOAD FILE IN THE RIGHT FORMAT FOR PROCESSING</Title>
        </Wrapper> */}
        <InputWrapper>
          <Aligner>
            <InputButton type="file" onChange={handleFileChange} accept="*" />
            {/* <Input type="file" onChange={handleFileChange} accept="*" /> */}
            <UploadButton disabled={loading} onClick={handleUpload}>
              {loading ? 'Processing...' : 'Upload'}
            </UploadButton>
            <DownloadButton href={BusinessLoanTemplate} download>
              Download sample
            </DownloadButton>
          </Aligner>
        </InputWrapper>
      </>
      {/* } */}


      {/* {!response  &&
        <>
          <Wrapper>
            <Title>UPLOAD CSV FILE IN THE RIGHT FORMAT FOR PROCESSING</Title>
          </Wrapper>
          <UploadForm>
          <input type="file" style={{width: '19rem',height: '5rem', color: 'black', padding:"1rem"}} onChange={handleFileChange} accept="*" />
            <UploadButton disabled={loading} onClick={handleUpload}>
              {loading ? 'Processing...' : 'Upload'}
            </UploadButton>
            <DownloadButton href={BusinessLoanTemplate} download>
              Download sample
            </DownloadButton>
          </UploadForm>
        </>
      } */}

      {response  &&
        <div style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridColumnGap: "5%",
              marginRight: "5%",
              marginLeft: "5%",
              marginBottom: "2rem",
              marginTop: "10px",
              alignItems: "start"
            }}
      >
        <div style={{marginBottom: "2rem", marginTop: "10px"}}>
          <p style={{marginBottom: "10px",
                      marginTop: "1rem",
                      minWidth: "100%",
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"}}
                    >
          Yearly Analysis
          </p>
          <SummaryTableNew
          res={response}
          />
        </div>
        <div style={{marginBottom: "2rem", marginTop: "10px"}}>
        <p style={{marginBottom: "10px",
                      marginTop: "1rem",
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"}}
                    >
          Range Analysis
          </p>
          <SummaryTableNewTransposeTwo
          res={response}
          />
        </div>
      </div>}

      {response && res.data.hasOwnProperty("summary") &&
      <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "55%", marginLeft: "5%"}}>
        <AmountTable
          data={calculateamounts(response)}
          />
      </div>}
      {/* {response && res.data.hasOwnProperty("summary") &&
      <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "55%", marginLeft: "5%"}}>
        <AmountTable
          value0={calculateamounts(response).value}
          value00={calculateamounts(response).value5}
          value1={calculateamounts(response).maxAmount}
          value2={calculateamounts(response).minAmount}
          />
      </div>} */}

      {response && response.data.balance.hasOwnProperty("Year1") && <p style={{
        backgroundColor: `${props => props.theme.colors[props.theme.themeType].background}`,
        color: 'white',
        marginRight: "5%",
        marginLeft: "5%",
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Processed Balance Cashflow: Year One
    </p>
    }
      {response && response.data.balance.hasOwnProperty("Year1") &&
      <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "5%", marginLeft: "5%"}}>
        <MyTable data={response.data.balance.Year1} />
      </div>}


      {response && response.data.balance.hasOwnProperty("Year2") &&<p style={{
        backgroundColor: `${props => props.theme.colors[props.theme.themeType].background}`,
        color: 'white',
        marginRight: "5%",
        marginLeft: "5%",
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Processed Balance Cashflow: Year Two
    </p>
    }

      {response && response.data.balance.hasOwnProperty("Year2")
       &&
      <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "5%", marginLeft: "5%"}}>
        <MyTable data={response.data.balance.Year2} />
      </div>}

      {response && response.data.balance.hasOwnProperty("Year1") &&<p style={{
        backgroundColor: `${props => props.theme.colors[props.theme.themeType].background}`,
        color: 'white',
        marginRight: "5%",
        marginLeft: "5%",
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Processed Credit and Debit Cashflows: Year One
    </p>
    }
      {response &&  response.data.creditDebit.hasOwnProperty("Year1") &&
      <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "5%", marginLeft: "5%"}}>
        <MySecondTable data={response.data.creditDebit.Year1} />
      </div>}

      {response && response.data.balance.hasOwnProperty("Year2") &&<p style={{
        backgroundColor: `${props => props.theme.colors[props.theme.themeType].background}`,
        color: 'white',
        marginRight: "5%",
        marginLeft: "5%",
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Processed Credit and Debit Cashflows: Year Two
    </p>
    }

      {response && response.data.creditDebit.hasOwnProperty("Year2") &&
        <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "5%", marginLeft: "5%"}}>
        <MySecondTable data={response.data.creditDebit.Year2} />
      </div>}

      {response && <p style={{
        backgroundColor: `${props => props.theme.colors[props.theme.themeType].background}`,
        color: 'white',
        marginRight: "5%",
        marginLeft: "5%",
        fontFamily: "Roboto",
        fontSize: "20px",
        marginTop: "10px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      Cashflow Description
    </p>
    }
      {response && <div style={{marginBottom: "2rem", marginTop: "10px", marginRight: "5%", marginLeft: "5%"}}>
        <DescriptionTable data={response.data.remarks} />
      </div>}

    </div>

  );
}

export default FileUploader;


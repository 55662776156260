import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
//   background-color: #f2f2f2;
`;

const TableHeading = styled.th`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  font-family: Roboto;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    // background-color: #f2f2f2;
  }
`;

function MyTable({ data }) {
  return (
    <TableWrapper>
      <TableHead>
        <tr>
          <TableHeading>Date</TableHeading>
          {/* <TableHeading>Balance (Count)</TableHeading> */}
          <TableHeading>Balance (Mean)</TableHeading>
          {/* <TableHeading>Balance (Sum)</TableHeading> */}
          <TableHeading>Three-Month Mean</TableHeading>
          <TableHeading>Five-Month Mean</TableHeading>
        </tr>
      </TableHead>
      <tbody>
        {data.map(row => (
          <TableRow key={row.Date}>
            <TableData>{row.Date}</TableData>
            {/* <TableData>{row['Balance (Count)']}</TableData> */}
            <TableData>{row['Balance (Mean)']}</TableData>
            {/* <TableData>{row['Balance (Sum)']}</TableData> */}
            <TableData>{row['SMA(3) of Balance (Mean)']}</TableData>
            <TableData>{row['SMA(5) of Balance (Mean)']}</TableData>
          </TableRow>
        ))}
      </tbody>
    </TableWrapper>
  );
}

export default MyTable;

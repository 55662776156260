import React from 'react';
// import styled from 'styled-components';
import FirstComponent from './FirstComponent';
import SecComponent from './SecComponent';

// const Container = styled.div`
//   display: grid;
//   grid-template-columns: 24% 74%;
//   grid-gap: 1%;
//   height: 400px;
// //   margin-bottom: 100px,
// `;


const ComponentsThree = ({ linearIcons, userData }) => {
  return (
    <div style={{height: '500px', marginTop: '30px', marginBottom: '50px',
    display: 'grid', gridTemplateColumns: ' 24% 74%', gridGap: '1%'}}>
      <FirstComponent linearIcons={linearIcons} userData={userData}/>
      <SecComponent defColor={linearIcons} userData={userData} />
    {/* <Container>
      <FirstComponent linearIcons={linearIcons} userData={userData}>
      </FirstComponent>
      <SecComponent defColor={linearIcons} userData={userData} />
    </Container> */}
    </div>
  );
};

export default ComponentsThree;

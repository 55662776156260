import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import { linearIcons } from 'components/styles/utils';


const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoadingSpinner = styled(CircularProgress)`
  animation: ${rotateAnimation} 1s linear infinite;
`;

const LoadingText = styled.div`
  margin-top: 10px;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  opacity: 0;
  animation: ${fadeAnimation} 2s ease-in-out infinite;
  animation-delay: 1s;
  transition: opacity 0.3s ease-in-out;
  color: ${linearIcons};
`;

const SoftwareLoading = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
      <LoadingText>
        Please, wait while the software is loading...
        {/* Please, wait while the <br />
        software is loading. */}
      </LoadingText>
    </LoadingContainer>
  );
};

export default SoftwareLoading;

import React from 'react'
import WelcomeBack from './WelcomeBack';
import Dashboard from './Dashboard';
import ProfileCard from './ProfileCard';


// import Image1 from 'assets/icons/chart-svgrepo-com.svg'
// import Image2 from 'assets/icons/bar-chart-stats-svgrepo-com.svg'
// import Image3 from 'assets/icons/chart-info-svgrepo-com.svg'
// import Image4 from 'assets/icons/chart-sales-performance-svgrepo-com.svg'
// import Image5 from 'assets/icons/chart-svgrepo-com.svg'
// import Image6 from 'assets/icons/data-storage-database-graph-business-chart-svgrepo-com.svg'
// import Image7 from 'assets/icons/profits-graph-svgrepo-com.svg'
// import Image8 from 'assets/icons/stats-pie-chart-svgrepo-com.svg'
// import Image9 from 'assets/icons/stats-svgrepo-com.svg'

const ComponentsTwo = ({linearIcons, userData, onLogout}) => {
    // const marginBottom = window.innerWidth >= 768 ? '100px' : '10px';
  return (
    <div style={{display: 'grid', gridTemplateColumns: '24% 46% 28%', gridGap: '1%',  height: '400px',
     marginTop: '30px',
    //  marginBottom,
     }}>
        <WelcomeBack defColor={linearIcons} userData={userData}/>
        <Dashboard defColor={linearIcons} userData={userData}/>
        <ProfileCard defColor={linearIcons} userData={userData}/>
    </div>
  )
}

export default ComponentsTwo

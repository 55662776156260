import React from 'react'
// import styled from 'styled-components';




// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import styled from 'styled-components';

// const ArrowIcon = styled.div`
//   // width: 20px;
//   // height: 20px;
//   // position: relative;
//   transition: transform 0.2s ease;


//   &:hover {
//     transform: translateX(5px);
//   }
// `;



const WelcomeBack = ({userData, defColor, onLogout}) => {
  // const navigate = useNavigate();

  // const handleLogout = () => {
  //   onLogout();
  //   navigate('/signin')
  // }

  // onClick={handleLogout}
  return (
    <div style={{
        // width: '300px',
        backgroundColor: 'red',
        borderRadius: '20px',
        background: defColor,
        position: 'relative',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    }}>
      <div style={{float: 'top', margin: '20px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ fontSize: '1.2rem', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
          Welcome back!
        </div>
        <div style={{ fontSize: '.8rem', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
          Nice to see you, {userData.name}!
        </div>
      </div>



        {/* <div style={{display: 'flex', position: 'absolute', bottom: '50px', width: '100%', cursor: 'pointer',
                  justifyContent: 'center'}}>
          <div style={{color: 'white'}} >Tap to logout</div>
          <ArrowIcon style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          </ArrowIcon>
        </div> */}


    </div>
  )
}

export default WelcomeBack
import React from 'react'
// import Image3 from 'assets/icons/chart-info-svgrepo-com.svg'
// import Image4 from 'assets/icons/chart-sales-performance-svgrepo-com.svg'
// import Image7 from 'assets/icons/profits-graph-svgrepo-com.svg'
// import Image8 from 'assets/icons/stats-pie-chart-svgrepo-com.svg'
// import CircularProgressBar from '../../CircularProgressBar';

const Dashboard = ({userData, defColor}) => {
  return (
    <div style={{
        backgroundColor: 'red',
        borderRadius: '20px',
        background: defColor,
        display: 'grid', gridTemplateRows: '20% 80%',
        padding: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
      }}>
          <div style={{ marginTop: '10px', margin: '10px 10px', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{ fontSize: '1.2rem', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
              Information Dashboard
            </div>
            <div style={{ fontSize: '.8rem', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
            Welcome to your information dashboard, {userData.name}!
            </div>
          </div>

          {/* <div style={{display: 'grid', gridTemplateColumns: '30% 60%', gridColumnGap: '10%'}}>
            <div style={{
                display: 'grid',
                gridTemplateRows: '58% 40%',
                gridRowGap: '2%',
                // width: '300px',
                height: '100%',
                // background: 'red',
                }}>
                  <div style={{marginTop: '30%'}}>
                    <svg viewBox="0 0 100 100">
                      <CircularProgressBar value={80} />
                    </svg>
                  </div>

                  <div style={{marginTop: '40px'}}>
                    <div
                      style={{
                        fontSize: '1rem',
                        fontWeight: '400',
                        padding: '0.1em 0',
                        color: 'white',
                        fontFamily: 'Century Gothic',
                        display: 'flex',
                        justifyContent: 'center',
                        }}
                    >
                      5,000 API Calls
                    </div>
                    <div
                      style={{
                        fontSize: '.8rem',
                        fontWeight: '400',
                        padding: '0.1em 0',
                        color: '#d8d8d8',
                        fontFamily: 'Century Gothic',
                        display: 'flex',
                        justifyContent: 'center',
                        }}
                    >
                      Remaining number of calls
                    </div>
                  </div>
            </div>*/}


            {/* <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'repeat(1fr, 2)',
                gridColumnGap: '4%',
                gridRowGap: '4%',
              }}
            >
              <div style={{position: 'relative'}}>
                 <div style={{position: 'absolute', bottom: '0', width: '100%', height: '65%', background: 'black', borderRadius: '10px',
                      display: 'grid', gridTemplateColumns: '60% 40%'}}>
                  <div style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center', padding: '5%'}}>
                    <div style={{ fontSize: '75%', padding: '0.1em 0', margin: '2px', marginLeft: '5px', color: 'white',
                                  fontFamily: 'Century Gothic', fontColor: "#d8d8d8" }}>
                      Total Number
                    </div>
                    <div style={{ fontSize: '2em', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic',
                                  fontWeight: '550', marginLeft: '5px' }}
                    >
                      1,200
                    </div>
                  </div>
                  <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <div style={{position: 'absolute', left: '0',  width: '90%', height: '60%', borderRadius: '12px',
                                 background: defColorNew,
                                 display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={Image7} alt="icon" style={{width: "25px", height: "25px", color: "#1DA1F2"}} />
                    </div>
                  </div>
                  </div>
              </div>

              <div style={{
                position: 'relative',
              }}>
                 <div style={{position: 'absolute', bottom: '0', width: '100%', height: '65%', background: 'black', borderRadius: '10px',
                      display: 'grid', gridTemplateColumns: '60% 40%'}}>
                  <div style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center', padding: '5%'}}>
                    <div style={{ fontSize: '75%', padding: '0.1em 0', margin: '2px', color: 'white',
                                  fontFamily: 'Century Gothic', fontColor: "#d8d8d8" , marginLeft: '5px'}}>
                      This Month
                    </div>
                    <div style={{ fontSize: '2em', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic',
                                  fontWeight: '550', marginLeft: '5px' }}
                    >
                      300
                    </div>
                  </div>
                  <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <div style={{position: 'absolute', left: '0',  width: '90%', height: '60%', borderRadius: '12px',
                                 background: defColorNew,
                                 display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={Image3} alt="icon" style={{width: "25px", height: "25px", color: "#1DA1F2"}} />
                    </div>
                  </div>
                  </div>
              </div>


              <div style={{
                position: 'relative',
              }}>
                 <div style={{position: 'absolute', top: '0', width: '100%', height: '65%', background: 'black', borderRadius: '10px',
                      display: 'grid', gridTemplateColumns: '60% 40%'}}>
                  <div style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center', padding: '5%'}}>
                    <div style={{ fontSize: '75%', padding: '0.1em 0', margin: '2px', color: 'white',
                                  fontFamily: 'Century Gothic', fontColor: "#d8d8d8", marginLeft: '5px' }}>
                      This Week
                    </div>
                    <div style={{ fontSize: '2em', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic',
                                  fontWeight: '550', marginLeft: '5px' }}
                    >
                      600
                    </div>
                  </div>
                  <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <div style={{position: 'absolute', left: '0',  width: '90%', height: '60%', borderRadius: '12px',
                                 background: defColorNew,
                                 display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={Image4} alt="icon" style={{width: "25px", height: "25px", color: "#1DA1F2"}} />
                    </div>
                  </div>
                  </div>
              </div>
              <div style={{
                position: 'relative',
              }}>
                 <div style={{position: 'absolute', top: '0', width: '100%', height: '65%', background: 'black', borderRadius: '10px',
                      display: 'grid', gridTemplateColumns: '60% 40%'}}>
                  <div style={{display: 'flex',  flexDirection: 'column', justifyContent: 'center', padding: '5%'}}>
                    <div style={{ fontSize: '75%', padding: '0.1em 0', margin: '2px', color: 'white',
                                  fontFamily: 'Century Gothic', fontColor: "#d8d8d8", marginLeft: '5px' }}>
                      Today
                    </div>
                    <div style={{ fontSize: '2em', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic',
                                  fontWeight: '550', marginLeft: '5px' }}
                    >
                      15
                    </div>
                  </div>
                  <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                    <div style={{position: 'absolute', left: '0',  width: '90%', height: '60%', borderRadius: '12px',
                                 background: defColorNew,
                                 display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <img src={Image8} alt="icon" style={{width: "25px", height: "25px", color: "#1DA1F2"}} />
                    </div>
                  </div>
                  </div>
              </div>


            </div>




          </div>*/}
      </div>
  )
}

export default Dashboard
import React from 'react';
import styled from 'styled-components';
// import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router-dom';
import FileUploader from "./FileUploader";
import {ScreenContainer, MainContent} from '../styles/GlobalStyle';
// import Sidebar from "components/Sidebar";

// const BusinessLoansContainer = styled.div`
//   display: flex;
//   height: 100vh;
//   margin-top: 5rem;
// `;

// const MainContent = styled.div`
//   flex-grow: 1;
//   padding: 24px;
//   margin-top: 20px;
// `;

const LogoutButton = styled.button`
  position: fixed;
  bottom: 24px;
  right: 24px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const BusinessLoans = ({ handleLogout }) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    handleLogout();
    navigate('/signin');
  };

  const userData = JSON.parse(localStorage.getItem('foundUser'));

  return (
    <>
      <ScreenContainer>
      {/* <Sidebar/> */}
        {/* <Sidebar user={userData} /> */}
        <MainContent>
          <FileUploader />
          <LogoutButton onClick={handleLogoutClick}>Logout</LogoutButton>
        </MainContent>
      </ScreenContainer>
    </>

  );
};

export default BusinessLoans;

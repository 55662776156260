import React from 'react'

const Title = ({userData}) => {
  return (
    <div style={{ marginTop: '10px', flexDirection: 'column', justifyContent: 'center'}}>
        <div style={{ fontSize: '1.4rem', padding: '0.1em 0', margin: '0', color: 'white', fontFamily: 'Century Gothic' }}>
            Projects
        </div>
        <div style={{ fontSize: '.8rem', padding: '0.1em 0', margin: '0',  color: 'white', fontFamily: 'Century Gothic' }}>
            Have a quick access to our services
        </div>
    </div>

  )
}

export default Title